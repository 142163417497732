import type { MutationHookOptions } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';
import type { MutationLoginCustomerVariables, MutationLoginCustomerParams } from '../index';

export const MUTATION_LOGIN_CUSTOMER = gql`
  mutation loginCustomer($loginInput: LoginInput!) {
    loginCustomer(loginInput: $loginInput) {
      email
    }
  }
`;

type LoginCustomerMutationOptions = MutationHookOptions<
  MutationLoginCustomerParams,
  MutationLoginCustomerVariables
>;

export const useLoginCustomer = (options?: LoginCustomerMutationOptions) => {
  const [mutationLoginCustomer, result] = useMutation<
    MutationLoginCustomerParams,
    MutationLoginCustomerVariables
  >(MUTATION_LOGIN_CUSTOMER, options);

  const loginCustomer = (loginInput: MutationLoginCustomerParams) => {
    mutationLoginCustomer({ variables: { loginInput } });
  };

  return [loginCustomer, result] as const;
};
