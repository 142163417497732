import styled from 'styled-components';

import { vars, media, pxToRem } from '$util/theme';

export const Styled = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${vars.quantumverse.reskinColors.neutral700};
  font-weight: ${vars.fontWeight.regular};

  .title {
    margin-top: 75px;
    margin-bottom: 20px;

    font-size: ${pxToRem(24)};
    font-weight: ${vars.fontWeight.semiBold};
    line-height: 28px;

    ${media.up('md')} {
      margin-top: 125px;
      margin-bottom: 50px;
      font-size: ${pxToRem(32)};
      font-weight: ${vars.fontWeight.semiBold};
      line-height: 34px;
    }
  }

  .Card {
    box-sizing: border-box;
    gap: 20px;
    min-width: 340px;

    padding: 40px 20px;

    .forgot-password {
      text-align: right;
    }

    .LoginForm {
      margin-top: 30px;
    }

    .submit {
      margin: 30px auto;
      .Button {
        width: 100%;
        span {
          width: 100%;
        }
      }
    }
  }

  .Banner {
    position: fixed;
    z-index: 10000;
    width: 100%;
  }

  .register {
    display: flex;
    margin: 40px 0;

    font-size: ${pxToRem(14)};
    .btn-link {
      text-decoration-color: ${vars.quantumverse.reskinColors.purple500};
      > span {
        font-size: ${pxToRem(14)};
        color: ${vars.quantumverse.reskinColors.purple500};
      }
    }
  }
`;
