import type { UrlObject } from 'url';
import { useCallback } from 'react';
import classNames from 'classnames';
import type { FormikValues } from 'formik';

import type { ApolloError } from '@apollo/client';
import { parseApolloErrors } from '$apollo/util';

import { Card } from '$components/basic/Card';
import { LoginForm } from '$components/composite/Login/components/LoginForm';
import Banner from '$components/basic/Banner';

import { redirect, changeUrl } from '$util/index';

import { BannerThemeTypes } from '$components/basic/Banner/themedBanner';
import type { CmsContentDictionary } from '$cms/index';
import { getShortTextByKey } from '$cms/getDataByKey';
import { useAppContext } from '$util/AppContext';
import { SimpleLink } from '@/components/atoms/SimpleLink';
import { Title } from '@/components/atoms/Title';
import { useLoginCustomer } from './gql/hooks/useLoginCustomer';

import { Styled } from './styled';

const getCmsData = (cmsData: CmsContentDictionary) => {
  const getShortText = getShortTextByKey.bind(cmsData);

  return {
    formTitle: getShortText('login.formTitle', 'Log in to your account'),
    newCustomerText: getShortText('login.newCustomer.text', 'New to Fixter?'),
    createAccountLabel: getShortText('login.createAccount.button.label', 'Create Account'),
    errorMessages: {
      invalidLoginDetails: getShortText(
        'login.invalidCredentialsError.text',
        'Incorrect username or password.'
      ),
    },
  };
};

interface LoginErrorMessage {
  invalidLoginDetails: string;
}

export const parseLoginError = (error: ApolloError, errorMessages: LoginErrorMessage): string => {
  const [err] = parseApolloErrors(error);
  const clientExpectedErrorCode = err?.clientExpectedErrorCode;
  switch (clientExpectedErrorCode) {
    case 'INVALID_LOGIN_DETAILS':
    case 'LOGIN_EXCEEDED_RETRIES':
      return errorMessages.invalidLoginDetails;
    default:
      throw new TypeError(`Unexpected clientExpectedErrorCode(${clientExpectedErrorCode}) trying to login`);
  }
};

const forgotPassword = () => {
  changeUrl({
    pathname: '/forgot-password',
  });
};

export interface LoginContainerProps {
  readonly getReturnTo: () => UrlObject;
  readonly className?: string;
}

export function LoginContainer({ className, getReturnTo }: LoginContainerProps): JSX.Element {
  const AppContext = useAppContext();
  const {
    cmsContent: { dictionary },
  } = AppContext;

  const { formTitle, newCustomerText, createAccountLabel, errorMessages } = getCmsData(dictionary);

  const [loginCustomer, { loading, error }] = useLoginCustomer({
    onCompleted: () => {
      redirect(getReturnTo());
    },

    onError: (err: ApolloError) => {
      try {
        return parseLoginError(err, errorMessages);
      } catch (e) {
        return e;
      }
    },
  });

  const login = (formValues: FormikValues) => {
    const { email, password } = formValues;
    loginCustomer({ email, password });
  };

  const onCreateAccount = useCallback(() => {
    changeUrl({
      pathname: '/register',
    });
  }, []);

  return (
    <Styled className={classNames('LoginContainer', className)}>
      {!loading && error && (
        <Banner type={BannerThemeTypes.Danger}>{errorMessages.invalidLoginDetails}</Banner>
      )}
      <Title>{formTitle}</Title>
      <Card className={className}>
        <LoginForm
          values={{}}
          onSubmit={login}
          onForgotPassword={forgotPassword}
          hasSubmitButton
          loading={loading}
        />
      </Card>
      <span className="register">
        {newCustomerText}&nbsp;&nbsp;
        <SimpleLink
          type={2}
          text={createAccountLabel}
          href="/register"
          onClick={(event) => {
            event.preventDefault();
            onCreateAccount();
          }}
        />
      </span>
    </Styled>
  );
}
