import styled from 'styled-components';
import { vars, media, pxToRem } from '$util/theme';

export const Styled = styled.div`
  ${media.up('md')} {
    width: 432px;
  }

  margin: 0px auto;
  font-weight: ${vars.fontWeight.regular};

  .FormItem {
    .Input {
      height: 60px;
    }
  }
  .loginButton {
    height: 56px;
    margin-top: 30px;
    font-size: 16px;
  }
  .forgot-password {
    ${media.up('md')} {
      text-align: right;
    }
  }

  .anticon-check-circle {
    display: none;
  }

  .-error {
    label {
      color: ${vars.red};
    }
    .item-error {
      color: ${vars.red};
      font-size: ${pxToRem(13)};
    }
  }
`;
