import type { FormikValues } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useCallback } from 'react';
import type { IFormProps } from '$components/composite/Form';
import Form from '$components/composite/Form';
import FormItem from '$components/composite/Form/FormItem';
import Input from '$components/composite/Form/Input';
import { PasswordInput } from '$components/composite/Form/PaswordInput';
import type { CmsContentDictionary } from '$cms/index';
import { getShortTextByKey } from '$cms/getDataByKey';
import { useAppContext } from '$util/AppContext';
import { SimpleLink } from '@/components/atoms/SimpleLink';
import { Button, DesignType } from '@/components/atoms/Button';
import { Styled } from './styled';

const getCmsData = (cmsData: CmsContentDictionary) => {
  const getShortText = getShortTextByKey.bind(cmsData);

  return {
    emailLabel: getShortText('login.email.label', 'Email address'),
    emailPlaceholder: getShortText('login.email.placeholder', 'Enter your email'),
    passwordLabel: getShortText('login.password.label', 'Password'),
    passwordPlaceholder: getShortText('login.password.placeholder', 'Enter your password'),
    forgotPasswordLabel: getShortText('login.forgotPassword.button.label', 'Forgot password?'),
    loginLabel: getShortText('login.button.label', 'Log in'),
    newCustomerText: getShortText('login.newCustomer.text', 'New to Fixter?'),
    createCustomerLabel: getShortText('login.createAccount.button.label', 'Create Account'),

    emailErrorInvalid: getShortText(
      'checkout.personalDetails.contactForm.emailErrorInvalid',
      "That email address doesn't look right"
    ),
    emailErrorMax: getShortText(
      'checkout.personalDetails.contactForm.emailErrorMax',
      'Email must be below 320 characters'
    ),
    emailErrorRequired: getShortText(
      'checkout.personalDetails.contactForm.emailErrorRequired',
      'Please enter your email address'
    ),
    passwordErrorRequired: getShortText(
      'checkout.personalDetails.contactForm.passwordErrorRequired',
      'Please enter your password'
    ),
  };
};

interface ValidationErrorProps {
  emailErrorInvalid: string;
  emailErrorMax: string;
  emailErrorRequired: string;
  passwordErrorRequired: string;
}

const getValidationSchema = (errorMessages: ValidationErrorProps) => {
  const { emailErrorInvalid, emailErrorMax, emailErrorRequired, passwordErrorRequired } = errorMessages;
  const shape = {
    email: Yup.string()
      .trim()
      .email(emailErrorInvalid)
      // eslint-disable-next-line no-template-curly-in-string
      .max(320, emailErrorMax)
      .required(emailErrorRequired),
    password: Yup.string().required(passwordErrorRequired),
  };
  return Yup.object().shape(shape);
};

export interface Credentials {
  readonly email: string;
  readonly password: string;
}

export interface LoginFormProps {
  readonly values: Partial<Credentials>;
  readonly onSubmit: (values: FormikValues) => void;
  readonly onForgotPassword: () => void;
  readonly hasSubmitButton?: boolean;
  readonly loading?: boolean;
  readonly formContext?: IFormProps['formContext'];
  readonly className?: string;
}

export function LoginForm({
  values,
  onSubmit,
  onForgotPassword,
  formContext,
  hasSubmitButton,
  loading,
  className,
  ...props
}: LoginFormProps): JSX.Element {
  const AppContext = useAppContext();
  const {
    cmsContent: { dictionary },
  } = AppContext;

  const {
    emailLabel,
    emailPlaceholder,
    passwordLabel,
    passwordPlaceholder,
    forgotPasswordLabel,
    loginLabel,
    newCustomerText,
    createCustomerLabel,
    ...errorMessages
  } = getCmsData(dictionary);

  return (
    <Styled className={classNames('LoginForm', className)} {...props}>
      <Form
        formik={{
          initialValues: values,
          validateOnChange: false,
          validateOnBlur: false,
          validationSchema: useCallback(() => getValidationSchema(errorMessages), [errorMessages]),
          onSubmit,
        }}
        formContext={formContext}
        noValidate
      >
        <FormItem name="email" label={emailLabel}>
          <Input type="email" placeholder={emailPlaceholder} />
        </FormItem>
        <FormItem name="password" label={passwordLabel}>
          <PasswordInput placeholder={passwordPlaceholder} />
        </FormItem>
        <div className="forgot-password">
          <SimpleLink
            type={1}
            text={forgotPasswordLabel}
            href="/forgot-password"
            onClick={(event) => {
              event.preventDefault();
              onForgotPassword();
            }}
          />
        </div>
        {hasSubmitButton && (
          <Button
            className="loginButton"
            designType={DesignType.CTA_DARK}
            size="L"
            type="submit"
            loading={loading}
            fullWidth
          >
            {loginLabel}
          </Button>
        )}
      </Form>
    </Styled>
  );
}
LoginForm.displayName = 'LoginForm';
