import type { ParsedUrlQuery } from 'querystring';
import Head from 'next/head';
import { Row, Col } from 'antd';
import type { GetServerSideProps } from 'next';
import { withApollo } from '$apollo/hooks';
import CustomerLayout from '$layout/CustomerLayout';
import { LoginContainer } from '$pages/login';
import { getUrlObject, parseStringUrlQuery } from '$util/index';
import { withServerSkipPageIfAuthenticated } from '$util/identity';
import { RETURN_TO_PARAM_NAME } from '$constants/loginReturnTo';
import type { CmsContentProps } from '$cms/index';
import { getShortTextByKey } from '$cms/getDataByKey';
import { GET_LOGIN_PAGE_CMS_QUERY } from '$components/pages/login/gql/queries';
import { QUERY_GET_FORM_CMS_CONTENT } from '$components/pages/checkout/personaldetails/gql/queries/getFormCmsContent';
import { GET_PASSWORD_CMS_QUERY } from '$components/pages/checkout/personaldetails/gql/queries/getPasswordCmsContent';
import { getMetaTitle } from '$components/pages/gql';

export interface LoginPageProps extends CmsContentProps {
  urlQuery: ParsedUrlQuery;
}

function LoginPage({ urlQuery, cmsContent }: LoginPageProps) {
  const getShortText = getShortTextByKey.bind(cmsContent?.dictionary);
  const pageTitle = getShortText('metaTitle.customer.login', 'Login | Fixter');

  const returnTo = urlQuery[RETURN_TO_PARAM_NAME];

  const getReturnUrlObj = () =>
    getUrlObject(
      typeof returnTo === 'string' && !returnTo.includes('/login') ? returnTo : '/customer/account'
    );

  return (
    <CustomerLayout>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Row>
        <Col>
          <LoginContainer getReturnTo={getReturnUrlObj} />
        </Col>
      </Row>
    </CustomerLayout>
  );
}

export const getServerSideProps: GetServerSideProps = withServerSkipPageIfAuthenticated(
  [QUERY_GET_FORM_CMS_CONTENT, GET_LOGIN_PAGE_CMS_QUERY, GET_PASSWORD_CMS_QUERY, getMetaTitle],
  ({ query: urlQuery }) => {
    const { returnTo } = parseStringUrlQuery(urlQuery);
    return returnTo;
  }
);

export default withApollo(LoginPage);
